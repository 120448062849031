@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;700;800;900&display=swap");

:root {
  --primary: rgba(168, 85, 247);
  --primary-touch: rgba(107, 33, 168);
  --secondary: rgba(138, 139, 197);
  --grey: rgba(160, 160, 160, 1);
  --overlay-surface: rgba(14,18,36,.7);
  --nav-height: 4rem;
  --desc: rgb(107, 114, 128);
  --option-touch: rgb(232, 237, 243);
  --top-desc: rgb(31, 41, 55);
  --alt-surface: rgb(243, 244, 246);
  --error: rgba(251, 91, 91, 1);
}


html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar,
.scrollbar-show::-webkit-scrollbar {
  @apply block w-[4px] h-[5px];
}

::-webkit-scrollbar-thumb {
  background: var(--overlay-surface);
  @apply rounded-[6.39px];
}
.content-container {
  @apply flex h-full w-full items-center px-2 sm:px-4 md:px-8 lg:px-12;
}

h1 {
  @apply font-bold text-2xl xl:text-5xl sm:text-3xl md:text-4xl md:font-extrabold;
}

h2 {
  @apply text-xl xl:text-4xl sm:text-2xl md:text-3xl font-extrabold;
}

h3 {
  @apply text-lg xl:text-3xl sm:text-xl md:text-2xl font-extrabold;
}

h4 {
  @apply text-base xl:text-2xl sm:text-lg md:text-xl font-extrabold;
}

.footer-link {
  @apply transition-all duration-300 hover:underline hover:text-gray-300;
}

.clickable-text {
  @apply transition-all duration-300 hover:underline hover:text-primary text-black;
}

.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-calendar__tile--active {
  background: #1087ff !important;
  background-color: #1087ff !important;
}

.react-calendar__tile:disabled {
  background-color: var(--grey) !important;
}